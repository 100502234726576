<template>
  <ul v-if="list.length > 0" class="warnings-summary">
    <li v-for="item in list" :key="item">{{ item }}</li>
  </ul>
</template>

<script>
export default{
  model:{
    prop: 'items',
  },
  props: { items: {type:Array, default:null }},
  computed: {
    list(){
      if(this.items == null || !Array.isArray(this.items))
        return [];
      return this.items;
    }
  }
}
</script>

<style>
  ul.warning-summary{
    border: solid 1px var(--ffvlWarning);
    background-color: var(--ffvlWarningBackground);
  }
  ul.warning-summary > li{
    color: var(--ffvlWarning);
  }
</style>
